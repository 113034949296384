import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static targets = ['init', 'mode'];
  static values = ['mode'];

  connect () {
    this.modal = Modal.getOrCreateInstance(this.element);
    document.addEventListener('turbo:submit-end', this.evalResponse.bind(this));
  }

  open ({ mode, callback, redirectPath }) {
    if (!navigator.cookieEnabled) {
      return;
    }

    this.callback = callback;
    this.redirectPath = redirectPath;

    this.modeTarget.value = mode || 'regular';
    this.initTarget.click();

    this.modal.show();
  }

  evalResponse () {
    window.setTimeout(() => {
      if (this.element.querySelector('.auth-callback')) {
        this.callCallback();
      }

      if (this.element.querySelector('.auth-reload-with-timer')) {
        this.reloadWithTimer();
      }
    }, 1000);
  }

  callCallback () {
    if (this.callback) {
      this.callback();
      this.callback = null;
    }
  }

  reload () {
    this.redirect();
  }

  reloadWithTimer () {
    window.setTimeout(() => {
      this.redirect();
    }, 6000);
  }

  redirect () {
    window.Turbo.cache.clear();
    
    if (this.redirectPath) {
      window.location.href = this.redirectPath;
    }

    window.location.reload();
  }

  disconnect () {
    document.removeEventListener('turbo:submit-end', this.evalResponse.bind(this));
  }
}
