import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  connect () {
    this.modal = Modal.getOrCreateInstance(this.element);
    
    for (const el of this.element.querySelectorAll('input[type="radio"]')) {
      el.addEventListener('change', () => {
        this.element.querySelector('button[type="submit"]').disabled = false;
      });
    }

    this.element.querySelector('form').addEventListener('turbo:submit-end', (e) => {
      for (const obj of this.element.querySelectorAll('.step1')) {
        obj.classList.add('d-none');
      }

      if (e.detail.fetchResponse.response.status === 200) {
        this.element.querySelector('.step2').classList.remove('d-none');
      } else {
        this.element.querySelector('.step3').classList.remove('d-none');
      }

      window.setTimeout(() => {
        this.modal.hide();
      }, 6000);
    });
  }

  open () {
    this.modal.show();
  }
}
