import { Controller } from '@hotwired/stimulus';
// import coreTranslations from 'ckeditor5/translations/de.js';

// import {
//   ClassicEditor,
//   BalloonToolbar,
//   BlockToolbar,
//   Bold,
//   Essentials,
//   Paragraph,
//   Indent,
//   Italic,
//   List,
//   RemoveFormat,
//   MediaEmbed,
//   MediaEmbedToolbar,
//   BlockQuote
// } from 'ckeditor5';

export default class extends Controller {
  static values = {
    inputId: String,
    uiLocale: 'de',
    contentLocale: 'de',
    enterMode: 'BR',
    toolbar: {
      type: Array,
      default: [
        'bold',
        'italic',
        '|',
        'blockQuote',
        'bulletedList',
        'numberedList',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
        'removeFormat'
      ]
    }
  };

  async connect () {
    await this.initCkeditor();
    this.editor.setData(document.getElementById(this.inputIdValue).value);
    
    this.element.closest('form').addEventListener('submit', this.writeDataToForm.bind(this));
  }

  async initCkeditor () {
    this.editor = await window.$CKEditor.ClassicEditor.create(this.scope.element, {
      plugins: [
        window.$CKEditor.ClassicEditor,
        window.$CKEditor.BalloonToolbar,
        window.$CKEditor.BlockToolbar,
        window.$CKEditor.Bold,
        window.$CKEditor.Essentials,
        window.$CKEditor.Paragraph,
        window.$CKEditor.Indent,
        window.$CKEditor.Italic,
        window.$CKEditor.List,
        window.$CKEditor.RemoveFormat,
        window.$CKEditor.MediaEmbed,
        window.$CKEditor.MediaEmbedToolbar,
        window.$CKEditor.BlockQuote,
      ],
      translations: [window.$CKEditor.coreTranslations],
      language: {
        ui: this.uiLocaleValue,
        content: this.contentLocaleValue,
      },
      toolbar: {
        items: this.toolbarValue
      },
      blockToolbar: this.toolbarValue,
      balloonToolbar: this.toolbarValue,
    });
  }

  setData (data) {
    this.editor.setData(data);
  }

  getData () {
    return this.editor.getData();
  }

  writeDataToForm () {
    document.getElementById(this.inputIdValue).value = this.editor.getData();
  }
}
