import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectAll', 'selectOne', 'actionButton'];

  connect () {
    this.selection = [];
    this.selecting = false;

    for (const el of this.selectAllTargets) {
      el.addEventListener('change', this.selectAll.bind(this));
    }

    for (const el of this.selectOneTargets) {
      el.addEventListener('change', this.selectOne.bind(this));
    }
  }

  selectAll (e) {
    for (const el of this.selectAllTargets) {
      el.checked = e.currentTarget.checked;
    }

    if (e.currentTarget.checked) { // is true when it was just checked
      for (const el of this.selectOneTargets) {
        el.checked = true;
        if (!this.selection.includes(el.value)) this.selection.push(el.value);
      }
    } else {
      for (const el of this.selectOneTargets) {
        el.checked = false;
      }

      this.selection = [];
    }

    this.toggleActionButtons();
  }

  selectOne (e) {
    const
      id = e.currentTarget.value,
      i  = this.selection.indexOf(id)
    ;

    i === -1 ? this.selection.push(id) : this.selection.splice(i, 1);

    for (const el of this.selectAllTargets) {
      el.checked = this.selection.length === this.selectOneTargets.length;
    }

    this.toggleActionButtons();
  }

  toggleActionButtons () {
    const selecting = this.selection.length > 0;

    if (this.selecting === selecting) return;

    for (const el of this.actionButtonTargets) {
      el.disabled = !selecting;
      el.classList.toggle('btn-light');
      el.classList.toggle('btn-info');
    }

    this.selecting = selecting;
  }

  copy (e) {
    const
      dataset  = e.currentTarget.dataset,
      response = window.confirm(dataset.confirm)
    ;

    Turbo.visit(`${dataset.url}?ids=${this.selection.join(',')}&duration=${dataset.value}`);
  }
}
