import '@hotwired/turbo-rails';

import './utils/fetch';
import './utils/castle';
import './utils/tracker';
import './utils/adhunter';
import './utils/ckeditor5';


import initSentry from './utils/sentry';
if (window.$Gunfinder.env !== 'development' && window.$Gunfinder.user.ua.supported) {
  initSentry('https://13504e9b86444989aa9927e19bb99755@o128241.ingest.sentry.io/5599126');
}

document.addEventListener('turbo:render', () => {
  window.$Gunfinder.Adhunter.load();

  if (window.Trustpilot) {
    window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
  }
});

import './controllers';
