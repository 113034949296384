import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static values = { modalId: String };

  connect () {
    if (this.hasModalIdValue) {
      this.modal = Modal.getOrCreateInstance(document.getElementById(this.modalIdValue));
    } else {
      this.modal = Modal.getOrCreateInstance(document.getElementById('delete-modal'));
    }

    if (window.location.hash == '#delete') {
      this.modal.show();
    }

    for (const el of this.element.querySelectorAll('input[type="radio"]')) {
      el.addEventListener('change', () => {
        this.element.querySelector('button[type="submit"]').disabled = false;
      });
    }
  }
}
