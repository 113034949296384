import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

function plainTextToHtml(text) {
  text = text
      // Encode &.
      .replace(/&/g, '&amp;')
      // Encode <>.
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      // Creates a paragraph for each double line break.
      .replace(/\r?\n\r?\n/g, '</p><p>')
      // Creates a line break for each single line break.
      .replace(/\r?\n/g, '<br>')
      // Replace tabs with four spaces.
      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      // Preserve trailing spaces (only the first and last one – the rest is handled below).
      .replace(/^\s/, '&nbsp;')
      .replace(/\s$/, '&nbsp;')
      // Preserve other subsequent spaces now.
      .replace(/\s\s/g, ' &nbsp;')
    ;

  if (text.includes('</p><p>') || text.includes('<br>')) {
    // If we created paragraphs above, add the trailing ones.
    text = `<p>${text}</p>`;
  }

  return text;
}

export default class extends Controller {
  static targets = ['form', 'reply', 'footer']
  static values = { type: String }
  static outlets = ['simple-editor']

  connect () {
    this.modal = Modal.getOrCreateInstance(this.element);

    this.element.addEventListener('show.bs.modal', () => {
      this.formTarget.querySelector('textarea').value = this.simpleEditorOutlet.getData()
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/(p|div)>/gi, '\n\n')
        .replace(/<[^>]+>/g, '')
      ;

      window.$Gunfinder.track('item ai optimiser: opened', { type: this.typeValue });
    });
  }

  formTargetConnected () {
    this.formTarget.addEventListener('turbo:submit-start', this.start.bind(this))
  }

  start () {
    if (this.hasFooterTarget) {
      this.footerTarget.classList.add('d-none');
    }

    window.$Gunfinder.track('item ai optimiser: submitted', { type: this.typeValue });
  }

  accept () {
    this.modal.hide();

    const data = plainTextToHtml(this.replyTarget.querySelector('.reply').textContent);
    this.simpleEditorOutlet.setData(data);

    window.$Gunfinder.track('item ai optimiser: accepted', { type: this.typeValue });
  }
}
