import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static values = { messagePath: String, meta: Object };

  connect () {
    if (window.$Gunfinder.user.signedIn) {
      this.messageModal = Modal.getOrCreateInstance(document.getElementById('message-modal'));

      document.getElementById('message-modal').addEventListener('show.bs.modal', () => {
        window.setTimeout(() => { document.getElementById('message_body').focus() }, 500);
      });
    }
    
    if (window.$Gunfinder.user.signedIn && window.location.hash === '#message') {
      this.messageModal.show();
    }
  }

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  message () {
    window.$Gunfinder.track('listings: internal clicked', this.metaValue);

    if (this.messagePathValue != '') {
      window.Turbo.visit(this.messagePathValue);
      return;
    }
    
    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'listing-message', redirectPath: `${window.location.pathname}#message` });
      return;
    }

    this.messageModal.show();
  }

  messageSuggestion (e) {
    e.currentTarget.closest('.modal-body').querySelector('textarea').value = e.currentTarget.innerText;
  }
}
