import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  isLocalStorageAvailable () {
    try {
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  connect () {
    if (window.$Gunfinder.user.ua.bot || !this.isLocalStorageAvailable()) {
      return;
    }

    this.modal   = Modal.getOrCreateInstance(this.element);
    this.$policy = null;

    if (window.localStorage.gunfinderCookies !== undefined) {
      // positive consent is set as early as possible in _head_gf.html.erb
      // if (window.localStorage.gunfinderCookies === 'true') {
      //   window.$Gunfinder.gtag('consent', 'update', { 'analytics_storage': 'granted' });
      // }

      return;
    }
    
    this.modal.show();
  }

  async loadPrivacyPolicy () {
    if (!this.$privacyPolicy) {
      const policy = await window.$Gunfinder.fetch('/privacy?vanilla=true');
      this.element.querySelector('.cookie-consent-policy').innerHTML = await policy.text();
      this.$privacyPolicy = true;
    }
  }

  async more (e) {
    e.preventDefault();

    await this.loadPrivacyPolicy();
    document.getElementById('cookie-consent-more').classList.toggle('d-none');
    document.getElementById('cookie-consent-anchor').scrollIntoView();
  }

  accept () {
    window.localStorage.gunfinderCookies = true;
    this.modal.hide();
    window.$Gunfinder.gtag('consent', 'update', { 'analytics_storage': 'granted' });
  }

  decline () {
    window.localStorage.gunfinderCookies = false;
    this.modal.hide();
  }
}
