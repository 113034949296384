import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static values = {
    autoOpen: false
  }

  connect () {
    this.modal = Modal.getOrCreateInstance(this.element);
    if (this.autoOpenValue) this.open();
  }

  open () {
    this.modal.show();
  }
}

