import { Controller } from '@hotwired/stimulus';

class Storage {
  constructor () {
    try {
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
      this.available = true;
    } catch (e) {
      this.available = false;
    }
  }

  read () {
    return this.available && window.localStorage.gunfinder ? JSON.parse(window.localStorage.gunfinder) : {};
  }

  write (data) {
    if (!this.available) {
      return;
    }

    window.localStorage.gunfinder = JSON.stringify(data);
  }
}

export default class extends Controller {
  connect () {
    this.$storage = new Storage();

    this.$data = { searchHistory: [] };
    
    if (this.$storage.available) {
      this.$data = this.$storage.read();
    }

    this.$data.searchHistory = this.$data.searchHistory || [];

    this.scope.element.addEventListener('addSearchHistory', (e) => {
      this.addSearchHistory(e.detail.query);
    });

    this.authFromUrl();

    this.observeTurboFrames();
  }

  persist () {
    this.$storage.write(this.$data);
    window.$Gunfinder.storage = this.$data;
  }

  get authController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('#auth-modal'), 'auth');
  }

  auth (e) {
    if (window.$Gunfinder.user.signedIn) {
      return;
    }

    if (e) {
      e.preventDefault();
    }

    this.authController.open({});
  }

  async signOut (e) {
    e.preventDefault();
    const token = await window.castle.createRequestToken();
    window.location.href = e.params.url + '?castle_request_token=' + token;
  }

  addSearchHistory (query) {
    const history = this.$data.searchHistory.map((query) => {
      return query.toLowerCase();
    });

    if (query && query !== '' && history.indexOf(query.toLowerCase()) === -1) {
      this.$data.searchHistory.unshift(query);
      this.$data.searchHistory = this.$data.searchHistory.slice(0, 8);
      this.persist();
    }
  }

  authFromUrl () {
    if (window.location.pathname === '/auth') {
      window.setTimeout(() => {
        this.auth();
      }, 1000);
    }
  }

  observeTurboFrames () {
    let
      href = null,
      body = null
    ;

    for (const frame of document.querySelectorAll('turbo-frame')) {
      frame.addEventListener('turbo:before-fetch-request', (e) => {
        href = e.detail.url.href;
        body = e.detail.fetchOptions?.body?.toString().split('&');
      });

      frame.addEventListener('turbo:frame-missing', async (e) => {
        const data = {
          status: e.detail.response.status,
          redirected: e.detail.response.redirected,
          redirected_url: e.detail.response.url,
          text: await e.detail.response.text(),
          frame: frame.id,
          href,
          body
        };

        if (!window.Sentry) {
          console.log(data);
          return;
        }

        console.log(data);
        // window.Sentry.addBreadcrumb({
        //   level: 'warning',
        //   data,
        // });
      });
    }
  }
}
