import { Controller } from '@hotwired/stimulus';
import { Toast } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  connect () {
    for(const el of [].slice.call(document.querySelectorAll('.toast'))) {
      const toast = new Toast(el);
      toast.show();
    }
  }
}
