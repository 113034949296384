import { Controller } from '@hotwired/stimulus';
import { Popover } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static targets = ['winner'];

  connect() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl));

    for (const el of this.element.querySelectorAll('input[type="checkbox"]')) {
      el.addEventListener('change', () => {
        this.element.querySelector('input[type="submit"]').click();
      });
    }

    if (this.hasWinnerTarget) {
      this.winnerTarget.addEventListener('click', (e) => {
        document.querySelector('#address-modal .modal-body').innerHTML = this.winnerTarget.dataset.address;
      });
    }
  }
}
