import { application } from './application';

import AppController from './app_controller.js';
application.register('app', AppController);

import ColorSchemeController from './color_scheme_controller.js';
application.register('color-scheme', ColorSchemeController);

import AuthController from './auth_controller.js';
application.register('auth', AuthController);

import DropdownController from './dropdown_controller.js';
application.register('dropdown', DropdownController);

import AdvancedSearchController from './advanced_search_controller.js';
application.register('advanced-search', AdvancedSearchController);

import CookiesController from './cookies_controller.js';
application.register('cookies', CookiesController);

import FavoritesController from './favorites_controller.js';
application.register('favorites', FavoritesController);

import ItemController from './item_controller.js';
application.register('item', ItemController);

import ListingController from './listing_controller.js';
application.register('listing', ListingController);

import AuctionController from './auction_controller.js';
application.register('auction', AuctionController);

import MessageFormController from './message_form_controller.js';
application.register('message-form', MessageFormController);

import MiniCollapseController from './mini_collapse_controller.js';
application.register('mini-collapse', MiniCollapseController);

import ListingManagerController from './listing_manager_controller.js';
application.register('listing-manager', ListingManagerController);

import ReportController from './report_controller.js';
application.register('report', ReportController);

import ReviewController from './review_controller.js';
application.register('review', ReviewController);

import ReviewFormController from './review_form_controller.js';
application.register('review-form', ReviewFormController);

import RichContentController from './rich_content_controller.js';
application.register('rich-content', RichContentController);

import SearchAutocompleteController from './search_autocomplete_controller.js';
application.register('search-autocomplete', SearchAutocompleteController);

import SearchController from './search_controller.js';
application.register('search', SearchController);

import ToastsController from './toasts_controller.js';
application.register('toasts', ToastsController);

import TabsController from './tabs_controller.js';
application.register('tabs', TabsController);

import LocaleSwitcherController from './locale_switcher_controller.js';
application.register('locale-switcher', LocaleSwitcherController);

import SellSwitchController from './sell_switch_controller.js';
application.register('sell-switch', SellSwitchController);

import CountdownController from './countdown_controller.js';
application.register('countdown', CountdownController);

import FeedbackController from './feedback_controller.js';
application.register('feedback', FeedbackController);

import MapsLoaderController from './maps_loader_controller.js';
application.register('maps-loader', MapsLoaderController);

import AnimatedCounterController from './animated_counter_controller.js';
application.register('animated-counter', AnimatedCounterController);

// import PopupController from './popup_controller.js';
// application.register('popup', PopupController);

import AccordionController from './accordion_controller.js';
application.register('accordion', AccordionController);

import ItemGallery from './item_gallery.js';
application.register('item-gallery', ItemGallery);

import Modal from './modal_controller.js';
application.register('modal', Modal);

import SecureFormController from './secure_form_controller.js';
application.register('secure-form', SecureFormController);

import InterestCloud from './interest_cloud_controller.js';
application.register('interest-cloud', InterestCloud);

import ChartController from './chart_controller.js';
application.register('chart', ChartController);

import AorBController from './aorb_controler.js';
application.register('aorb', AorBController);

import CSDVDLControler from './csv_dl_controller.js';
application.register('csv-dl', CSDVDLControler);

import CategoryCalibersController from './category_calibers_controller.js';
application.register('category-calibers', CategoryCalibersController);

import ClassifiedOptimiserController from './classified_optimiser_controller.js';
application.register('classified-optimiser', ClassifiedOptimiserController);

import SimpleEditorController from './simple_editor_controller.js';
application.register('simple-editor', SimpleEditorController);

import PictureManagerController from './picture_manager_controller.js';
application.register('picture-manager', PictureManagerController);

import PurchaseController from './purchase_controller.js';
application.register('purchase', PurchaseController);

import AuctionSoldController from './auction_sold_controller.js';
application.register('auction-sold', AuctionSoldController);

import AuctionUnsoldController from './auction_unsold_controller.js';
application.register('auction-unsold', AuctionUnsoldController);

import CloudflareTurnstileController from './cloudflare_turnstile_controller.js';
application.register('cloudflare-turnstile', CloudflareTurnstileController);
