import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['categoryId', 'calibers'];

  connect () {
    this.categoryIdTarget.addEventListener('change', () => {
      this.toggleCaliber();
    });
  }

  toggleCaliber (e) {
    const group = this.categoryIdTarget.options[this.categoryIdTarget.selectedIndex].closest('optgroup')?.dataset.id;

    if (['weapons', 'ammunition'].includes(group)) {
      this.calibersTarget.classList.remove('d-none');
    } else {
      this.calibersTarget.classList.add('d-none');
    }
  }
}
