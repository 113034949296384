import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default class extends Controller {
  static values = { form: String, listingModal: Boolean };
  static targets = ['simpleFormButton', 'simpleForm', 'advancedFormButton', 'advancedForm'];

  connect () {
    if (this.formValue == 'simple') {
      this.enableSimpleForm();
    } else {
      this.enableAdvancedForm();
    }


    if (this.listingModalValue) {
      const modal = Modal.getOrCreateInstance(document.getElementById('listing-modal'));
      modal.show();
    }
  }

  enableSimpleForm () {
    this.simpleFormButtonTarget.classList.remove('btn-light');
    this.simpleFormButtonTarget.classList.add('btn-primary');

    this.advancedFormButtonTarget.classList.add('btn-light');
    this.advancedFormButtonTarget.classList.remove('btn-primary');

    this.simpleFormTarget.classList.remove('d-none');
    this.advancedFormTarget.classList.add('d-none');
  }

  enableAdvancedForm () {
    this.advancedFormButtonTarget.classList.remove('btn-light');
    this.advancedFormButtonTarget.classList.add('btn-primary');

    this.simpleFormButtonTarget.classList.add('btn-light');
    this.simpleFormButtonTarget.classList.remove('btn-primary');

    this.advancedFormTarget.classList.remove('d-none');
    this.simpleFormTarget.classList.add('d-none');
  }
}
