import { Controller } from '@hotwired/stimulus';
import * as FilePond from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';

import {
  openEditor,
  createDefaultImageReader,
  createDefaultImageWriter,
  processImage,
  getEditorDefaults,
  setPlugins,
  plugin_crop,
  plugin_annotate,
  plugin_redact,
  markup_editor_defaults,
} from '@pqina/pintura/pintura.js';

import locale_de_DE, {
  MarkupEditor as locale_markup_editor_de_DE,
} from '@pqina/pintura/locale/de_DE/core/de_DE.js';

import locale_annotate_de_DE from '@pqina/pintura/locale/de_DE/annotate/de_DE.js';
import locale_crop_de_DE from '@pqina/pintura/locale/de_DE/crop/de_DE.js';
import locale_redact_de_DE from '@pqina/pintura/locale/de_DE/redact/de_DE.js';

FilePond.registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster,
  FilePondPluginImageEditor,
);

setPlugins(plugin_crop, plugin_annotate, plugin_redact);


export default class extends Controller {
  static targets = ['input'];
  static values = {
    files: {
      type: Array,
      default: []
    }
  };

  connect () {
    this.processing = 0;

    this.filepond = FilePond.create(this.inputTarget, this.filePondConfig());
    this.filepondRoot = this.element.querySelector('.filepond--root');

    this.filepondRoot.addEventListener('FilePond:addfilestart', (e) => {
      if (e.detail.file.status !== 9) return;

      this.processing += 1;

      if (this.processing === 1) {
        this.dispatch('upload-started');
      }
    });

    this.filepondRoot.addEventListener('FilePond:addfile', (e) => {
      if (e.detail.file.status !== 9) return;
      
      if (e.detail.error) {
        this.processing -= 1;
      }

      if (this.processing === 0) {
        this.dispatch('upload-finished');
      }
    });

    this.filepondRoot.addEventListener('FilePond:processfile', () => {
      this.processing -= 1;

      if (this.processing === 0) {
        this.dispatch('upload-finished');
      }
    });
  }

  filePondConfig () {
    return {
      allowMultiple: true,
      allowReorder: true,
      maxFiles: 20,
      credits: {},
      itemInsertLocation: 'after',
      files: this.filesValue.map((source) => { return { source, karl: true, options: { type: 'local' } }; }),

      labelDecimalSeparator: ',',
      labelThousandsSeparator: '.',
      labelIdle: 'Drag & Drop oder <span class="filepond--label-action"> auswählen </span>',
      labelInvalidField: 'Feld beinhaltet ungültige Dateien',
      labelFileWaitingForSize: 'Dateigröße berechnen',
      labelFileSizeNotAvailable: 'Dateigröße nicht verfügbar',
      labelFileLoading: 'Laden',
      labelFileLoadError: 'Fehler beim Laden',
      labelFileProcessing: 'Upload läuft',
      labelFileProcessingComplete: 'Upload abgeschlossen',
      labelFileProcessingAborted: 'Upload abgebrochen',
      labelFileProcessingError: 'Fehler beim Upload',
      labelFileProcessingRevertError: 'Fehler beim Wiederherstellen',
      labelFileRemoveError: 'Fehler beim Löschen',
      labelTapToCancel: 'abbrechen',
      labelTapToRetry: 'erneut versuchen',
      labelTapToUndo: 'rückgängig',
      labelButtonRemoveItem: 'Entfernen',
      labelButtonAbortItemLoad: 'Verwerfen',
      labelButtonRetryItemLoad: 'Erneut versuchen',
      labelButtonAbortItemProcessing: 'Abbrechen',
      labelButtonUndoItemProcessing: 'Rückgängig',
      labelButtonRetryItemProcessing: 'Erneut versuchen',
      labelButtonProcessItem: 'Upload',
      
      imageValidateSizeLabelFormatError: 'Bildtyp nicht unterstützt',
      imageValidateSizeLabelImageSizeTooSmall: 'Bild ist zu klein',
      imageValidateSizeLabelImageSizeTooBig: 'Bild ist zu groß',
      imageValidateSizeLabelExpectedMinSize: 'Mindestgröße: {minWidth} × {minHeight}',
      imageValidateSizeLabelExpectedMaxSize: 'Maximale Größe: {maxWidth} × {maxHeight}',
      imageValidateSizeLabelImageResolutionTooLow: 'Auflösung ist zu niedrig',
      imageValidateSizeLabelImageResolutionTooHigh: 'Auflösung ist zu hoch',
      imageValidateSizeLabelExpectedMinResolution: 'Mindestauflösung: {minResolution}',
      imageValidateSizeLabelExpectedMaxResolution: 'Maximale Auflösung: {maxResolution}',

      allowFileSizeValidation: true,
      maxFileSize: '10MB',
      labelMaxFileSizeExceeded: 'Datei ist zu groß',
      labelMaxFileSize: 'Maximale Dateigröße: {filesize}',
      labelMaxTotalFileSizeExceeded: 'Maximale gesamte Dateigröße überschritten',
      labelMaxTotalFileSize: 'Maximale gesamte Dateigröße: {filesize}',
      
      allowFileTypeValidation: true,
      acceptedFileTypes: ['image/*'],
      labelFileTypeNotAllowed: 'Dateityp ungültig',
      fileValidateTypeLabelExpectedTypes: 'Erwartet {allButLastType} oder {lastType}',

      allowFilePoster: true,
      filePosterMaxHeight: window.$Gunfinder.mobile ? 217 : 305,

      styleItemPanelAspectRatio: 0.7, // ensures equal height in filepond grid view. fucks up langwaffen pics though

      imageEditor: {
        createEditor: openEditor,
        imageReader: [createDefaultImageReader, {}],
        imageWriter: [createDefaultImageWriter, {}],

        imageProcessor: processImage,

        editorOptions: {
          ...getEditorDefaults({
             ...markup_editor_defaults,
             locale: {
              ...locale_de_DE,
              ...locale_markup_editor_de_DE,
              ...locale_crop_de_DE,
              ...locale_annotate_de_DE,
              ...locale_redact_de_DE,
              cropLabel: '',
              redactLabel: '',
              annotateLabel: '',
             },
             utils: ['crop', 'annotate', 'redact'],

             cropEnableButtonRotateRight: true,
          }),
        },
      },

      server: {
        instantUpload: false,
        url: '/uploads',
        process: {
          url: `?folder=listings&field=${this.inputTarget.name}`,
          headers: window.$Gunfinder.getDefaultHeaders()
        },
        revert: async (uniqueFileId, load, error) => {
           await window.$Gunfinder.fetch(`/uploads/generic?file=${encodeURIComponent(uniqueFileId)}`, { method: 'DELETE' });
          load();
        },
        load: async (source, load, error, progress, abort, headers) => {
          // https://javascript.info/window.$Gunfinder.fetch-progress
          const
            response = await window.$Gunfinder.fetch(`/uploads/url?path=${source}`),
            reader   = response.body.getReader(),
            length   = { total: +response.headers.get('Content-Length'), received: 0 },
            chunks   = []
          ;

          while (true) {
            const { done, value } = await reader.read();

            if (done) break;

            length.received += value.length;
            progress(true, length.received, length.total);
            chunks.push(value);
          }
          load(new Blob(chunks, { type: response.headers.get('Content-Type') }));

          return {
            abort: () => {
              abort();
            },
          };
        },
      }
    };
  }
}
