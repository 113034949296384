import { Controller } from '@hotwired/stimulus';

import Autocomplete from '../utils/autocomplete';

export default class extends Controller {
  static values = { apiUrl: String };

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  connect () {
    const $this = this;

    this.autocomplete = new Autocomplete(this.element, {
      dropdownClass: 'w-100',
      data: [],
      threshold: 0,
      maximumItems: 10,
      onSelectItem ({ value }) {
        $this.element.value = value;
        $this.element.dispatchEvent(new Event('search-autocomplete.selected'));
        
        if ($this.element.closest('form')) {
          $this.element.closest('form').submit();
        }

        $this.appController.addSearchHistory(value);
      }
    });

    this.renderSuggestions();
    this.element.addEventListener('input', this.renderSuggestions.bind(this));
  }

  async renderSuggestions (e) {
    const suggestions = await this.fetchSuggestions();

    if (!suggestions) return;

    const data = suggestions.map((suggestion, i) => {
      return {
        label: `<i class="bi bi-${suggestion.id ? 'search' : 'clock-history'}"></i> <span class="ms-1">%{label}</span>`,
        value: suggestion.query
      };
    });

    if (!e) {
      this.autocomplete.options.data = data;
    } else {
      this.autocomplete.setData(data);
    }
  }

  async fetchSuggestions () {
    const query = this.element.value;

    if (!query || query === '') {
      return this.appController.$data.searchHistory.map((query) => {
        return { query };
      });
    }

    const request = await window.$Gunfinder.fetch(this.apiUrlValue + '?query=' + encodeURIComponent(query));
    return request?.status == 200 ? request.json() : null;
  }
}
