// Because importing the editor multiple times causes errors ... ffs

import {
  ClassicEditor,
  BalloonToolbar,
  BlockToolbar,
  AutoLink,
  BlockQuote,
  Bold,
  Essentials,
  Paragraph,
  ParagraphButtonUI,
  Heading,
  HeadingButtonsUI,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  Indent,
  Italic,
  Link,
  List,
  RemoveFormat,
  MediaEmbed,
  MediaEmbedToolbar,
  PasteFromOffice,
  SimpleUploadAdapter,
  Table,
  TableCellProperties,
  TableColumnResize,
  TableToolbar,
} from 'ckeditor5';

import coreTranslations from 'ckeditor5/translations/de.js';

window.$CKEditor = {
  ClassicEditor,
  BalloonToolbar,
  BlockToolbar,
  AutoLink,
  BlockQuote,
  Bold,
  Essentials,
  Paragraph,
  ParagraphButtonUI,
  Heading,
  HeadingButtonsUI,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  Indent,
  Italic,
  Link,
  List,
  RemoveFormat,
  MediaEmbed,
  MediaEmbedToolbar,
  PasteFromOffice,
  SimpleUploadAdapter,
  Table,
  TableCellProperties,
  TableColumnResize,
  TableToolbar,
  coreTranslations,
};
